<template>
    <div id="app">
        <div class="navbar is-dark is-fixed-top">
            <div class="navbar-brand">
                <a class="navbar-item" target="_blank" href="/Українська_національна_революція_середини_ХVІІ_ст_формування.pdf">
                    <b-tooltip always label="лекція" position="is-right">
                        <img src="./assets/images/icons/file-pdf-outline.svg" height="28">
                    </b-tooltip>
                </a>
            </div>
<!--            <div class="navbar-menu is-active">-->
<!--                   <div class="navbar-end">-->
<!--                       <div class="navbar-item">-->
<!--                       {{answersByPoll.length}} / {{questions.length}}-->
<!--                   </div>-->
<!--               </div>-->
<!--            </div>-->
        </div>
        <div class="hero test is-fullheight" v-for="(quest, index) of normalizedQuestions" :key="index" :style="{'background-image': `url(${require('./assets/images/'+ (index + 1)+'.jpg')})`}" v-waypoint="{ active: currentQuest !== index, callback: onWaypoint(index), options: intersectionOptions }" :id="'quest'+index" v-if="index == 0 || (answersByPoll[index-1])">
            <div class="hero-body">
                <div class="columns is-multiline width-shadow">
                    <div class="column is-full">
                        <p class="title has-text-weight-bold is-4" v-text="quest.title"/>
                        <p class="subtitle" v-if="quest.desc" v-text="quest.desc"/>
                    </div>
                    <div class="column is-full">
                        <b-field v-for="(answer, answerIndex) of quest.answers" :key="answerIndex">
                            <b-radio v-if="quest.correctAnswers.length === 1" v-model="checkboxStore[index]" :native-value="answerIndex" @input="(val) => markAnswer(index, answerIndex, true)"> {{answer.answer}}</b-radio>
                            <b-checkbox v-else @input="(val) => markAnswer(index, answerIndex, val)"> {{answer.answer}}</b-checkbox>
                        </b-field>
                    </div>
                </div>
            </div>
<!--            <div class="hero-foot">-->
<!--                <div class="level">-->
<!--                    <div class="level-right ">-->
<!--                        <div class="level-item">-->
<!--                            <b-button class="is-primary">Далі</b-button>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="hero is-fullheight" :class="{'is-danger': parrots <= 0, 'is-orange': parrots > 0}" v-if="answersByPoll.length == questions.length">
            <div class="hero-body">
                <div>
                <p class="title" v-if="parrots > 0">
                    {{parrots> 0 ? 'Вітаннячка' : 'Вітаннячка, ти пробив дно!'}}
                </p>
                <div class="subtitle">
                    Тримай {{parrots}} попугайчиків
                    <div class="columns is-multiline" style="font-size: 3em">
                        <div class="is-flex is-flex-wrap-wrap" style="font-size: 6vw">
                            <PartyParrot class="" v-for="n in parrots" :key="n"/>
                        </div>
                    </div>
                    Від тебе {{correctAnswers}} правильних відповідей з {{countCorrectAllAnswers}} можливих. <br>
                    Не правильних - {{notCorrectAnswers}}
                </div>
                </div>
            </div>
        </div>
        <div v-else class="notification">
            Надайте відповідь
        </div>
    </div>
</template>

<script>
import PartyParrot from './components/PartyParrot.vue'
import shuffle from "lodash.shuffle";
import Vue from "vue"

export default {
    name: 'App',
    components: {
        PartyParrot
        // HelloWorld
    },
    data() {
        return {
            intersectionOptions: {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    threshold: [0.5, 0.5] // [0.25, 0.75] if you want a 25% offset!
                },
            checkboxStore: {},
            answersByPoll: [],
            questions: [
                {
                    title: "Які чинники зумовили масове народне повстання навесні 1648 р. ?",
                    correctAnswers: [
                        "відсутність власної держави",
                        "прогресуюча втрата національної еліти",
                        "церковний розкол",
                        "наростаюче закріпачення селянства",
                    ],
                    answers: [
                        "відсутність туалетного папіру у продовольчих магазинах",
                        "подорожчання цінни на паливо",
                        "відсутність електропостачання",
                        "масове поширення короновірусної інфекції",
                        "падіння ціни за Bitcoin",
                        "твіт Ілона Маска"
                    ]
                },
                {
                    title: "Які події відносяться до першого єтапу Української національної революції ?",
                    desc: "весна 1648 по серпень 1649",
                    correctAnswers: [
                        "перемога під Жовтими Водами",
                        "перемога під Пилявцями",
                        "укладення Зборівського мирного договору",
                        "укладення Білоцерківського мирного договору",
                    ],
                    answers: [
                        "укладення Сепаратизького мирного договору",
                        "перемога під Москвою",
                    ]
                },
                {
                    title: "В наслідок чого під час третього єтапу суттєво зменшилася територія Української держави ?",
                    correctAnswers: [
                        "чергової зради татар",
                        "поразки під Берестечком",
                    ],
                    answers: [
                        "поява туалетного папіру у продовольчих магазинах",
                        "Третій рейх почав контратаку з півдня",
                        "затопило",
                        "Богдан Хмельницький пішов у відставку"
                    ]
                },
                {
                    title: "У якому році Українську державу було подіділенно на Лівобережну у складі Московської держави й Правобережну в складі Речі Посполитої",
                    correctAnswers: [
                        '1667'
                    ],
                    answers: [
                        "1665",
                        "1666",
                        "1668",
                        "1669",
                        "1665",
                        "1664",
                        "1663"
                    ]
                },
                {
                    title: "Місце перших перемог масового народного повстання?",
                    correctAnswers: [
                        'Під Жовтими Водами',
                        'Корсунем'
                    ],
                    answers: [
                        "Київ",
                        "Львів",
                        "під Дніпром",
                        "річка Дністер"
                    ]
                },
                {
                    title: "Параметри, які характеризують будь-яку державу?",
                    correctAnswers: [
                        'кордон',
                        'система влади',
                    ],
                    answers: [
                        'завод',
                        'корупція',
                        'освіта',
                        'миша'

                    ]
                }
            ],
            currentQuest: 0
        }
    },
    computed: {
        normalizedQuestions() {
            return this.questions.map((question) => ({
                ...question,
                answers: shuffle([
                    ...question.answers.map((answer) => ({answer, correct: false})),
                    ...question.correctAnswers.map((answer) => ({answer, correct: true}))
                ])
            }));
        },
        answers(){
            return this.answersByPoll.map((answers, questId) => Object.keys(answers).filter((answerId) => answers[answerId] !== false).map((answerId) => answers[answerId] === this.normalizedQuestions[questId].answers[answerId].correct))
        },
        correctAnswers(){
          return this.answers.map((quest) => quest.filter((answer) => answer === true).length).reduce((quest, curr) => quest + curr, 0)
        },
        notCorrectAnswers(){
          return this.answers.map((quest) => quest.filter((answer) => answer === false).length).reduce((quest, curr) => quest + curr, 0)
        },
        countAllAnswers(){
            return this.normalizedQuestions.map((quest) => quest.answers.length).reduce((quest, curr) => quest + curr, 0)
        },
        countCorrectAllAnswers(){
            return this.normalizedQuestions.map((quest) => quest.answers.filter((answer) => answer.correct).length).reduce((quest, curr) => quest + curr, 0)
        },
        parrots(){
            const maxBall = 12;
            const notCorrectAll = this.countAllAnswers-this.countCorrectAllAnswers;
            return Math.round((this.correctAnswers / this.countCorrectAllAnswers) * maxBall - (this.notCorrectAnswers / notCorrectAll) * maxBall)
        }

    },
    methods: {
        markAnswer(questIndex, answerIndex, val)  {
                const answer = this.normalizedQuestions[questIndex].answers[answerIndex];

                let answersByPoll = this.answersByPoll[questIndex] ?? {};

                console.log(this.questions[questIndex].correctAnswers)
                if(this.questions[questIndex].correctAnswers.length === 1){
                    answersByPoll = {}
                }
                answersByPoll[answerIndex] = val;

                Vue.set(this.answersByPoll, questIndex, answersByPoll);
        },
        onWaypoint (questIndex) {
            return  ({ going, direction }) => {
                // going: in, out
                // direction: top, right, bottom, left
                if (going === this.$waypointMap.GOING_IN) {
                    // console.log(questIndex + ' waypoint going in!')
                    const nextElm = document.getElementById('quest'+(questIndex));
                    if(nextElm !== null) {
                        nextElm.scrollIntoView({ behavior: "smooth"});
                    }

                    this.currentQuest = questIndex;
                }

                if (going === this.$waypointMap.GOING_OUT) {
                    // console.log(questIndex + 'waypoint going out!')
                }
            }
        }
    }
}
</script>

<style lang="scss">
#app {
    font-family: Ubuntu, Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    //max-width: 1023px;
}

.test {
    //background-image: url('./assets/images/1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    p {
        &.width-shadow {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}

*.width-shadow {
    background-color: rgba(0, 0, 0, 0.7);
}
</style>
